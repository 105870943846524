import axios from "axios";
const runtimeConfig = useRuntimeConfig();
const config = useRuntimeConfig();
export async function getPaymentOptions(
  orderValue: number,
  currency: string,
  countryCode: undefined,
  phoneNumber: undefined
) {
  try {
    const response = await fetch(
      `${config.public.tamaraApiURL}/checkout/payment-types?country=${countryCode}&phone=${phoneNumber}&currency=${currency}&order_value=${orderValue}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${config.public.authToken}`, // Replace with your actual API key or token
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Failed to fetch:", error);
  }
}

export async function checkPaymentOptions(
  orderValue: number,
  currency: string,
  countryCode: undefined,
  phoneNumber: undefined,
  isVIP: undefined
) {
  try {
    const response = await fetch(
      ` ${config.public.tamaraApiURL}/checkout/payment-options-pre-check`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${config.public.authToken}`,
        },
        body: JSON.stringify({
          country: countryCode,
          order_value: {
            amount: orderValue,
            currency: currency,
          },
          phone_number: phoneNumber,
          is_vip: isVIP,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Failed to fetch:", error);
  }
}

export async function getIDVerification() {
  try {
    const response = await fetch(
      `${config.public.tamaraApiURL}/merchants/customer/id-verification-status`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${config.public.authToken}`, 
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Failed to fetch:", error);
  }
}

export async function createCheckoutSession(orderDetails: any) {
  try {
    const response = await axios.post(
      "https://api-sandbox.tamara.co/checkout",
      orderDetails,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${config.public.authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating checkout session:", error);
    throw error;
  }
}

export async function captureOrder(orderId: any) {
  try {
    const response = await axios.post(
      `https://api-sandbox.tamara.co/payments/capture`,
      {
        order_id: orderId,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error capturing order:", error);
    throw error;
  }
}

export async function refundOrder(orderId: any) {
  try {
    const response = await axios.post(
      `https://api-sandbox.tamara.co/payments/simplified-refund/${orderId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error refunding order:", error);
    throw error;
  }
}
